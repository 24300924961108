<template>
  <v-row>
    <!-- Start Single Portfolio  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      class="mt--40"
      v-for="(item, i) in portfolioContent"
      :key="i"
    >
      <div class="portfolio">
        <div class="thumbnail-inner">
          <v-img class="thumbnail" :src="item.src"></v-img>
          <v-img class="bg-blr-image" :src="item.src"></v-img>
        </div>
        <div class="content">
          <div class="inner">
            <p>{{ item.meta }}</p>
            <h4>
              {{ item.title }}
              <!-- <router-link to="portfolio-details">{{ item.title }}</router-link> -->
            </h4>
            <div class="portfolio-button">
              <!-- <router-link class="rn-btn" to="/portfolio-details{{item.id}}"
                >Case Study</router-link
              > -->
              
              <a v-bind:href="'/portfolio-details' + item.id" class="rn-btn">Detail</a>
            </div>
          </div>
        </div>
      </div>
    </v-col>
    <!-- End Single Portfolio  -->
  </v-row>
</template>

<script>
  export default {
    data() {
      return {
        portfolioContent: [
          {
            src: require("../../assets/images/project/unico/dashboard.png"),
            meta: "Web Development using Codeigniter HMVC",
            title: "ERP Web System Unico Tractors Indonesia",
            id: "/unico",
          },
          {
            src: require("../../assets/images/project/asiantexsb/dashboard.png"),
            meta: "Web Development using Native PHP",
            title: "ERP Web System Asiantex",
            id: "/sb-web",
          },
          {
            src: require("../../assets/images/project/18est/dashboard.png"),
            meta: "Web Development using Codeigniter MVC",
            title: "POS System 18EST Store",
            id: "/18est",
          },
          {
            src: require("../../assets/images/project/bigjill/dashboard.png"),
            meta: "Web Development using Codeigniter MVC",
            title: "Company Profile And Catalog Bigjill",
            id: "/bigjill",
          },
          {
            src: require("../../assets/images/project/synergybn/dashboard.png"),
            meta: "Web Development using PHP Native",
            title: "ERP Web System Synergy Business Network",
            id: "/synergy",
          },
          {
            src: require("../../assets/images/project/sb/dashboard.png"),
            meta: "Desktop Development using Delphi (Pascal)",
            title: "ERP System at PT.Setia Busana Textile",
            id: "/sb-desktop",
          },
          {
            src: require("../../assets/images/project/sb/dashboard_mobile.png"),
            meta: "Mobile Development using Android Studio",
            title: "Mobile Application at PT.Setia Busana Textile",
            id: "/sb-mobile",
          },
          {
            src: require("../../assets/images/project/unico/dashboardmobile.png"),
            meta: "Mobile Development using React Native",
            title: "Mobile Application at PT.Unico Tractors Indonesia",
            id: "/ums-mobile",
          },
          {
            src: require("../../assets/images/project/kps/kpsporfo.png"),
            meta: "Web ERP KPS",
            title: "Web Application at KPS",
            id: "/kps",
          },
          {
            src: require("../../assets/images/project/wpbigjill/bigjillporfo.png"),
            meta: "Web E-Commerce Bigjill",
            title: "Web E-Commerce Application at Bigjill",
            id: "/BigjillWP",
          },
          {
            src: require("../../assets/images/project/agrinusa/agrinusamobile.png"),
            meta: "Mobile Agrinusa",
            title: "Mobile Application at Agrinusa",
            id: "/AgrinusaMobile",
          },
          {
            src: require("../../assets/images/project/csa/csa.png"),
            meta: "Web CSA",
            title: "Web Application at Cahaya Surya Abadi",
            id: "/csa",
          },
          {
            src: require("../../assets/images/project/kme/kme.png"),
            meta: "Web KME",
            title: "Web Application at Kemilau Mentari",
            id: "/kme",
          },
          {
            src: require("../../assets/images/project/ubox/ubox.png"),
            meta: "Web Ubox",
            title: "Web Company Profile at UBOX",
            id: "/ubox",
          },
          {
            src: require("../../assets/images/project/act/act.png"),
            meta: "Web ACRT",
            title: "Web Company Profile at PT. Asia Central Telematika",
            id: "/act",
          },
          {
            src: require("../../assets/images/project/bcs/bcs.png"),
            meta: "Web BCS",
            title: "Web E-commerce at Bandung Choral Society",
            id: "/bcs",
          },
          {
            src: require("../../assets/images/project/ncgym/1.png"),
            meta: "Web NCGYM",
            title: "Web membership Gym at NCGYM",
            id: "/ncgym",
          },
          {
            src: require("../../assets/images/project/haikamu/0.png"),
            meta: "Mobile Chatbot",
            title: "Mobile Chatbot with ai diagnosis",
            id: "/haikamu",
          },
        ],
      };
    },
  };
</script>
