<template>
  <v-row align="start" class="row--35">
    <v-col lg="12" md="12" sm="12" cols="12">
      <div class="section-title text-center mb--50 mb_sm--30 mb_md--30">
        <h2 class="heading-title">Contact Us</h2>
        <p class="description">
          If you have any inquiries or requests, feel free to contact us using the form below.
        </p>
      </div>
      <div class="form-wrapper">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <ValidationProvider name="name" rules="required" v-slot="{ errors }">
              <label>
                <input
                  type="text"
                  v-model="formData.name"
                  placeholder="Your Name *"
                  class="input-field"
                />
                <span class="input-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider>

            <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
              <label>
                <input
                  type="email"
                  v-model="formData.email"
                  placeholder="Your Email *"
                  class="input-field"
                />
                <span class="input-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider>

            <ValidationProvider name="subject" rules="required" v-slot="{ errors }">
              <label>
                <input
                  type="text"
                  v-model="formData.subject"
                  placeholder="Subject"
                  class="input-field"
                />
                <span class="input-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider>

            <ValidationProvider name="message" rules="required" v-slot="{ errors }">
              <label>
                <textarea
                  v-model="formData.message"
                  placeholder="Your Message"
                  class="input-field"
                ></textarea>
                <span class="input-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider>

            <button class="rn-button-style--2 btn_solid" type="submit" value="submit">
              Submit
            </button>
          </form>
        </ValidationObserver>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import axios from "axios";
import Swal from "sweetalert2"; // Import SweetAlert2

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      formData: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
      telegramApiUrl: "https://api.telegram.org//sendMessage",
      chatId: "-", // Chat ID of the group or user
    };
  },
  methods: {
    async onSubmit() {
      // Prepare the message for Telegram
      const telegramMessage = `
        <b>Name:</b> ${this.formData.name}\n
        <b>Email:</b> ${this.formData.email}\n
        <b>Subject:</b> ${this.formData.subject}\n
        <b>Message:</b> ${this.formData.message}
      `;

      try {
        // Send the message to the Telegram bot
        const response = await axios.post(this.telegramApiUrl, {
          chat_id: this.chatId,
          text: telegramMessage,
          parse_mode: "HTML", // To send message in HTML format
        });

        // Show success alert using SweetAlert2
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Message sent successfully!",
          showConfirmButton: false,
          timer: 2000,
        });

        // Reset form after successful submission
        this.formData = {
          name: "",
          email: "",
          subject: "",
          message: "",
        };
      } catch (error) {
        // Show error alert using SweetAlert2
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to send message. Please try again.",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    },
  },
};
</script>

<style scoped>
.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.input-error {
  color: red;
  font-size: 12px;
  margin-top: -5px;
}

.rn-button-style--2 {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.rn-button-style--2:hover {
  background-color: #0056b3;
}

.form-wrapper {
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
}

.section-title {
  margin-bottom: 20px;
}
</style>